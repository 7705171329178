import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Booking_failed = () => {
    return (
        <div>

            <Header />
            <section class="bg-inner section-b-space success-section">
                <div class="container" style={{ marginTop: "4rem" }}>
                    <div class="row success-detail mt-0">
                        <div class="col">
                            <img src="../assets/images/hotel/booking-failed.png" class="img-fluid blur-up lazyload" alt="" />
                            <h2>oops ! we are unable to process your payment</h2>
                            <p>looks like we encountered an error. please try again. if you continue to have issue, try another
                                payment method.</p>
                            <button type="submit" class="btn btn-solid color1">try again</button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default Booking_failed

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <footer>
                <div className="footer section-b-space section-t-space">
                    <div className="container">
                        <div className="row order-row">
                            <div className="col-xl-2 col-md-6 order-cls">
                                <div className="footer-title mobile-title">
                                    <h5>Contact Us</h5>
                                </div>
                                <div className="footer-content" style={{ textAlign: "left" }}>
                                    <div className="contact-detail">
                                        <div className="footer-logo">
                                            <Link to="/"><img
                                                src="../assets/images/icon/logo10.png"
                                                alt="Abhyaranyan Farmstay Logo"
                                                className="img-fluid lazyload"
                                                style={{ height: 66, width: 198 }}
                                            /></Link>
                                        </div>
                                        <p>
                                            Welcome to Abhyaranyan Farmstay, where luxury meets comfort and every
                                        </p>
                                        <ul className="contact-list">
                                            <li>
                                                <i className="fas fa-map-marker-alt" /> Village- Madhti-Ahatmal, PO- Raipur, Behat, Saharanpur, India, Uttar Pradesh
                                            </li>
                                            <li>
                                                <i className="fas fa-phone-alt" /> 098714 33555
                                            </li>
                                            <li>
                                                <i className="fas fa-envelope" /> abhyaranyaretreat@gmail.com
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3">
                                <div className="footer-space" style={{ textAlign: "left" }}>
                                    <div className="footer-title">
                                        <h5>About</h5>
                                    </div>
                                    <div className="footer-content">
                                        <div className="footer-links">
                                            <ul>
                                                <li>
                                                    <Link to="/About"> <a>About Us</a></Link>
                                                </li>

                                                <li>
                                                    <Link to="/Profilelogin"><a href="#">Login</a></Link>
                                                </li>
                                                <li>
                                                    <Link to=""><a href="#">Terms &amp; Conditions</a></Link>
                                                </li>
                                                <li>
                                                    <a href="#">Privacy</a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="footer-title">
                                    <h5>Our Location</h5>
                                </div>
                                <div className="footer-content">
                                    <div className="footer-map">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6898.411532514413!2d77.61373860704734!3d30.174115599140112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390eda3acd4e88a1%3A0xbcf54d7e1275fde4!2sBehat%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1723006700877!5m2!1sen!2sin"
                                            width="600"
                                            height="450"
                                            style={{ border: 0 }}
                                            allowFullScreen=""
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 order-cls" style={{ textAlign: "left" }}>
                                <div className="footer-space">
                                    <div className="footer-title">
                                        <h5>Useful Links</h5>
                                    </div>
                                    <div className="footer-content">
                                        <div className="footer-links">
                                            <ul>
                                                <li>
                                                    <a href="#">Home</a>
                                                </li>
                                                <li>
                                                    <Link to="/Profilelogin"><a href="#">Profile Login</a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Signup"><a href="#">Signup</a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Packages"><a href="#">Packages</a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Orchid"><a href="#">Orchard</a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Dining"><a href="#">Dining</a></Link>
                                                </li>
                                                <li>
                                                    <Link to="/Experiences"><a href="#">Experiences</a></Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="footer-title">
                                    <h5>New Topics</h5>
                                </div>
                                <div className="footer-content">
                                    <div className="footer-blog">
                                        <div className="media">
                                            <div className="img-part">
                                                <a href="#">
                                                    <img
                                                        src="https://caelumhotels.com/assets/images/cab/blog-footer/Blog-1.png"
                                                        className="img-fluid lazyload"
                                                        alt="Recent News 1"
                                                    />
                                                </a>
                                            </div>
                                            <div className="media-body" style={{ textAlign: "left" }}>
                                                <h5>Recent News</h5>
                                                <p>
                                                    The Abhyaranyan Farmstay has been making headlines recently for several significant updates and
                                                </p>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="img-part">
                                                <a href="">
                                                    <img
                                                        src="https://caelumhotels.com/assets/images/cab/blog-footer/Blog-2.png"
                                                        className="img-fluid lazyload"
                                                        alt="Recent News 2"
                                                    />
                                                </a>
                                            </div>
                                            <div className="media-body" style={{ textAlign: "left" }}>
                                                <h5>Recent News</h5>
                                                <p>
                                                    The Abhyaranyan Farmstay is intensifying its commitment to sustainability by implementing
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="footer-social">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/abhyaranyan/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/abhyaranyan/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="copy-right">
                                    <p>
                                        Copyright 2024-2025 by <i className="fas fa-heart" /> Nd Techland Pvt Ltd
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;

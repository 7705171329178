import React, { useEffect, useState } from 'react';
import './Searchsection.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';

const Searchsection = () => {
    const [rooms, setRooms] = useState([{ id: 'counter1', count: 1 }]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [checkInDate, setCheckInDate] = useState(null);
    const [checkOutDate, setCheckOutDate] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const popup = document.getElementById('popup');
            const searchBody = document.getElementById('search-body');
            if (popup && searchBody && !popup.contains(event.target) && !searchBody.contains(event.target)) {
                setPopupVisible(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const addRoom = () => {
        if (rooms.length >= 6) {
            alert("Maximum of 6 rooms allowed.");
            return;
        }
        setRooms((prevRooms) => [
            ...prevRooms,
            { id: `counter${prevRooms.length + 1}`, count: 1 },
        ]);
    };

    const deleteRoom = () => {
        if (rooms.length > 1) {
            setRooms((prevRooms) => prevRooms.slice(0, -1));
        }
    };

    const incrementCount = (id) => {
        setRooms((prevRooms) =>
            prevRooms.map((room) => {
                if (room.id === id) {
                    if (room.count >= 3) {
                        alert("Maximum of 3 guests per room allowed.");
                        return room;
                    }
                    return { ...room, count: room.count + 1 };
                }
                return room;
            })
        );
    };

    const decrementCount = (id) => {
        setRooms((prevRooms) =>
            prevRooms.map((room) =>
                room.id === id && room.count > 1 ? { ...room, count: room.count - 1 } : room
            )
        );
    };

    const formatDateForUrl = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleSearch = () => {
        const totalrooms = rooms.length;
        const totalguest = rooms.reduce((total, room) => total + room.count, 0);

        const checkInFormatted = checkInDate ? formatDateForUrl(checkInDate) : formatDateForUrl(new Date());
        const checkOutFormatted = checkOutDate ? formatDateForUrl(checkOutDate) : formatDateForUrl(new Date());

        const queryParams = new URLSearchParams({
            checkInDate: checkInFormatted,
            checkOutDate: checkOutFormatted,
            totalrooms,
            totalguest,
        }).toString();

        // Navigate to the Booking page with query parameters
        navigate(`/Booking?${queryParams}`);
    };

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const checkInPlaceholder = formatDateForUrl(today);
    const checkOutPlaceholder = formatDateForUrl(tomorrow);

    return (
        <div>
            <div className="search-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <div className="search-panel" id="searchBar">
                                <div className="search-section shadow">
                                    <div className="search-box">
                                        <div className="left-part">
                                            <div className="search-body">
                                                <h6>Check In</h6>
                                                <DatePicker
                                                    selected={checkInDate}
                                                    onChange={(date) => setCheckInDate(date)}
                                                    placeholderText={checkInPlaceholder}
                                                    id="datepicker"
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            <div className="search-body">
                                                <h6>Check Out</h6>
                                                <DatePicker
                                                    selected={checkOutDate}
                                                    onChange={(date) => setCheckOutDate(date)}
                                                    placeholderText={checkOutPlaceholder}
                                                    id="datepicker1"
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            <div className="search-body" id="search-body" onClick={() => setPopupVisible(true)} style={{ cursor: "pointer" }}>
                                                <h6>{`${rooms.length} Room${rooms.length > 1 ? 's' : ''}, ${rooms.reduce((total, room) => total + room.count, 0)} Guest${rooms.reduce((total, room) => total + room.count, 0) > 1 ? 's' : ''}`}</h6>
                                            </div>
                                        </div>
                                        <div className="search-body btn-search">
                                            <div className="right-part">
                                                <button onClick={handleSearch} className="btn btn-solid color1">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {popupVisible && (
                                <div id="popup" className="popup">
                                    <div className="popup-content">
                                        <div className="room-control">
                                            <h6>Rooms </h6>
                                            <h6>Guests </h6>
                                        </div>
                                        <hr />
                                        {rooms.map((room, index) => (
                                            <div key={room.id} className="room-control">
                                                <span>Room {index + 1}</span>
                                                <button onClick={() => decrementCount(room.id)}>-</button>
                                                <span>{room.count}</span>
                                                <button onClick={() => incrementCount(room.id)}>+</button>
                                            </div>
                                        ))}
                                        <hr />
                                        <div className="room-control">
                                            <div className="col-md-6 col-xs-6 text-center">
                                                <a onClick={deleteRoom} className="guestRoomPickerPopUp__lessRoom text-danger"
                                                    style={{ cursor: "pointer" }}>Delete Room</a>
                                            </div>
                                            <div className="col-md-6 col-xs-6 text-center">
                                                <a onClick={addRoom} className="guestRoomPickerPopUp__addRoom text-primary"
                                                    style={{ cursor: "pointer" }}>Add Room</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Searchsection;

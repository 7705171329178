import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Banner.css';

// Custom Arrow Component
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={`${className} custom-arrow slick-prev`}
      onClick={onClick}
    >
    </button>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={`${className} custom-arrow slick-next`}
      onClick={onClick}
    >
    </button>
  );
};

const Banner = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
        // Extract the banners array from the response
        setBanners(response.data.data.banners);

        console.log('===',response.data.data.banners )
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div>
      <Slider {...settings}>
        {banners.map((banner, index) => (
          <div key={index} className="home_section hotel_layout slide-1 p-0">
            <div>
              <div className="home">
                <img
                  src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`}
                  className="img-fluid lazyload bg-img bg-img1"
                  alt={banner.description || ''}
                />
                <div className="home-content">
                  <div>
                    {/* Additional content can go here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Banner;

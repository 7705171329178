import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Accommodation.css';

const Accommodation = () => {
    const [banners, setBanners] = useState([]);
    const [accommodationData, setAccommodationData] = useState([]);

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
                if (Array.isArray(response.data.data.accommodationlist)) {
                    setBanners(response.data.data.accommodationlist);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };

        const fetchAccommodationData = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/AccommodationPage');
                if (Array.isArray(response.data.data.accommodation)) {
                    setAccommodationData(response.data.data.accommodation);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching accommodation data:', error);
            }
        };

        fetchBanners();
        fetchAccommodationData();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0px",
        arrows: true,
        className: 'center-slider-cab arrow-classic topTour mt-0',
    };

    return (
        <div>
            <Header />

            {Array.isArray(banners) && banners.map((banner, index) => (
                <div className="home_section hotel_layout slide-1 p-0" key={index}>
                    <div>
                        <div className="home">
                            <img src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`} className="bg-img img-fluid blur-up lazyload" alt="" />
                            <div className="home-content">
                                <div>
                                    {/* Additional content if needed */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {accommodationData.map((accommodation, index) => {
                const roomImages = JSON.parse(accommodation.imageJson || '[]');

                return (
                    <section key={index} className="about_section">
                        <div className="container">
                            <div className="title-3">
                                <span className="title-label">{accommodation.title}</span>
                                <h2>{accommodation.title}<span>{accommodation.title}</span></h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="about_img">
                                        <img
                                            src={`https://admin.abhyaranyan.com/RoomsUploadimage/${accommodation.accomedetionroomImages}`}
                                            className="img-fluid lazyload"
                                            alt={accommodation.title}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about_content">
                                        <div>
                                            <h5>{accommodation.offer}</h5>
                                            <h2>{accommodation.title}</h2>
                                            <p>{accommodation.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Slider Section for Images */}
                        <section className="small-section">
                            <div className="container ratio_59">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Slider {...settings}>
                                            {roomImages.map((image, imgIndex) => (
                                                <div key={imgIndex}>
                                                    <div className="topTour_box mt-0">
                                                        <div className="image-section">
                                                            <img
                                                                src={`https://admin.abhyaranyan.com/RoomsUploadimage/${image.path}`}
                                                                className="img-fluid blur-up lazyload bg-img"
                                                                alt={`Slide ${imgIndex}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </section>
                );
            })}

            <Footer />
        </div>
    );
}

export default Accommodation;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Dining = () => {

    const [banners, setBanners] = useState([]);
    const [diningData, setDiningData] = useState([]);

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
                console.log('API Response:', response.data); // Check the response structure
                if (Array.isArray(response.data.data.dininglist)) {
                    setBanners(response.data.data.dininglist);
                    console.log("-----", response.data.data.dininglist);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };

        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/DiningPage');
                if (response.data.succeeded) {
                    setDiningData(response.data.data.diningss);
                }
            } catch (error) {
                console.error('Error fetching the dining data', error);
            }
        };

        fetchBanners();
        fetchData();
    }, []);



    return (
        <div>
            <Header />
            {Array.isArray(banners) && banners.map((banner, index) => (
                <div className="home_section hotel_layout slide-1 p-0">
                    <div>
                        <div className="home">
                            <img src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`} class="bg-img img-fluid blur-up lazyload" alt="" />
                            <div className="home-content">
                                <div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}


            <section className="portfolio-creative small-section ratio_55">
                <div className="container">
                    <div className="order-section row m-0">
                        {diningData.map((item) => (
                            <React.Fragment key={item.id}>
                                <div className="col-lg-4 p-0">
                                    <div className="img-sec">
                                        <img
                                            src={`https://admin.abhyaranyan.com/RoomsUploadimage/${item.image}`} // Adjust this path if necessary
                                            className="img-fluid blur-up lazyload bg-img"
                                            alt={item.title}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 p-0">
                                    <div className="content-sec center-con">
                                        <div>
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>

                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}


export default Dining;

import React, { useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import './Profilelogin.css';
import { Link } from 'react-router-dom';
import Login from '../src/images/Login.png';

const Profilelogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://api.abhyaranyan.com/api/Signup/ProfileLogin', {
                username: email,
                password: password
            });

            if (response.data.succeeded) {
                setSuccessMessage('Login Successful!');
                setErrorMessage('');
                // Handle successful login (e.g., redirect to another page)
            } else {
                setErrorMessage(response.data.message || 'Login failed.');
                setSuccessMessage('');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
            setSuccessMessage('');
        }
    };

    return (
        <div>
            <Header />
            <section className="breadcrumb-section pt-0">
                <img src={Login} className="bg-img img-fluid blur-up lazyload" alt="" />
                <div className="breadcrumb-content">
                    <div>
                        <h2>Login</h2>
                        <nav aria-label="breadcrumb" className="theme-breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Login</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="title-breadcrumb">Rica</div>
            </section>
            <section className="section-b-space dark-cls animated-section">
                <div className="background-image">
                    <img src="../assets/images/cab/grey-bg.jpg" alt="" className="img-fluid blur-up lazyload bg-img" />
                </div>
                <div className="overlay">
                    <div className="animation-section">
                        <div className="cross po-1"></div>
                        <div className="cross po-2"></div>
                        <div className="round po-4"></div>
                        <div className="round po-5"></div>
                        <div className="round r-y po-8"></div>
                        <div className="square po-10"></div>
                        <div className="square po-11"></div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="offset-lg-3 col-lg-6 offset-sm-2 col-sm-8 col-12">
                                <div className="account-sign-in">
                                    <div className="title">
                                        <h3>Login</h3>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group" style={{ textAlign: "left" }}>
                                            <label htmlFor="exampleInputEmail1">User name or Email address</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter email"
                                                style={{ textAlign: "left" }}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                        </div>
                                        <div className="form-group" style={{ textAlign: "left" }}>
                                            <label htmlFor="exampleInputPassword1">Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Password"
                                                style={{ textAlign: "left" }}
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group form-check" style={{ textAlign: "left" }}>
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                                        </div>
                                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                        <div className="button-bottom">
                                            <button type="submit" className="w-100 btn btn-solid">Login</button>
                                            <div className="divider">
                                                <h6>or</h6>
                                            </div>
                                            <Link to="/Signup">
                                                <button type="button" className="w-100 btn btn-solid btn-outline">
                                                    Create Account
                                                </button>
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Profilelogin;

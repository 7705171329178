import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Experiences = () => {
    const [banners, setBanners] = useState([]);
    const [experiences, setExperiences] = useState([]);

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
                if (Array.isArray(response.data.data.experienceslist)) {
                    setBanners(response.data.data.experienceslist);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };

        const fetchExperiences = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/Experiences');
                if (Array.isArray(response.data.data.experiences)) {
                    setExperiences(response.data.data.experiences);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching experiences:', error);
            }
        };

        fetchBanners();
        fetchExperiences();
    }, []);

    return (
        <div>
            <Header />

            {Array.isArray(banners) && banners.map((banner, index) => (
                <div key={index} className=" home_section hotel_layout slide-1 p-0">
                    <div>
                        <div className="home" >
                            <img
                                src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`}
                                className="img-fluid lazyload bg-img bg-img1"
                                alt=""
                            />
                            <div className="home-content">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <section className="about_section">
                <div className="container">
                    <div className="title-3">
                        <span className="title-label">Experiences</span>
                        <h2>Experiences<span>Experiences</span></h2>
                    </div>
                    <h6>
                        Spend your days exploring lush orchards, befriending cows, and lazing by the river. Enjoy hiking, jungle safaris, and boating adventures. Visit the Kaleshwar Mahadev Math Mandir and the Gurudwara Paonta Sahib ji for spiritual enrichment. Engage in fun indoor and outdoor games, creating lasting memories in this idyllic retreat.
                    </h6>
                    <br />
                    {experiences.map((experience, index) => (
                        <div key={index} className="row mb-4">
                            {index % 2 === 0 ? (
                                <>
                                    <div className="col-lg-6">
                                        <div className="about_img">
                                            <img src={`https://admin.abhyaranyan.com/RoomsUploadimage/${experience.image}`} className="img-fluid lazyload" alt={experience.title} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="about_content">
                                            <div>
                                                <h5>{experience.title}</h5>
                                                <p>{experience.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-lg-6">
                                        <div className="about_content">
                                            <div>
                                                <h5>{experience.title}</h5>
                                                <p>{experience.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="about_img">
                                            <img src={`https://admin.abhyaranyan.com/RoomsUploadimage/${experience.image}`} className="img-fluid lazyload" alt={experience.title} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </section>


            <Footer />
        </div>
    );
};

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', background: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%' }}
            onClick={onClick}
        >
            <i className="fas fa-chevron-right"></i> {/* Use Font Awesome or any other icon library */}
        </div>
    );
};

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', background: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%' }}
            onClick={onClick}
        >
            <i className="fas fa-chevron-left"></i> {/* Use Font Awesome or any other icon library */}
        </div>
    );
};

export default Experiences;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Awesomeservice = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Fetch the data from the API
    axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/ServicesList')
      .then(response => {
        if (response.data.succeeded && response.data.data.services) {
          setServices(response.data.data.services);
        }
      })
      .catch(error => {
        console.error('Error fetching services:', error);
      });
  }, []);

  return (
    <div>
      <section className="section-b-space animated-section">
        <div className="animation-section">
          <div className="cross po-1"></div>
          <div className="cross po-2"></div>
          <div className="cross po-3"></div>
          <div className="round po-4"></div>
          <div className="round po-5"></div>
          <div className="round r-2 po-6"></div>
          <div className="round r-2 po-7"></div>
          <div className="round r-y po-8"></div>
          <div className="round r-y po-9"></div>
          <div className="square po-10"></div>
          <div className="square po-11"></div>
          <div className="square s-2 po-12"></div>
        </div>
        <div className="container">
          <div className="title-3">
            <span className="title-label">Our</span>
            <h2>Our service<span>Service</span></h2>
          </div>
          <div className="row service_section">
            {services.map(service => (
              <div key={service.id} className="col-lg-4 col-6">
                <div className="service-wrap">
                  <div>
                    <div className="service-icon">
                      <img 
                        src={`https://admin.abhyaranyan.com/RoomsUploadimage/${service.image}`} 
                        className="img-fluid lazyload" 
                        alt={service.title} 
                      />
                    </div>
                    <h5>{service.title}</h5>
                    <p>{service.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Awesomeservice;

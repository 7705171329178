import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';
import Home from './Home';
import Header from './Header';
import Footer from './Footer';
import About from './About';
import Gallery from './Gallery';
import Accommodation from './Accommodation';
import Dining from './Dining';
import Orchid from './Orchid';
import Packages from './Packages';
import Experiences from './Experiences';
import Profilelogin from './Profilelogin';
import Signup from './Signup';
import Booking from './Booking';
import Viewrooms from './Viewrooms';
import Booking_sucess from './Booking_sucess';
import Booking_failed from './Booking_failed';
function App() {

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Header" element={<Header />} />
          <Route path="/Footer" element={<Footer />} />
          <Route path="/About" element={<About />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Accommodation" element={<Accommodation />} />
          <Route path="/Dining" element={<Dining />} />
          <Route path="/Orchid" element={<Orchid />} />
          <Route path="/Packages" element={<Packages />} />
          <Route path="/Experiences" element={<Experiences />} />
          <Route path="/Profilelogin" element={<Profilelogin />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Booking" element={<Booking />} />
          <Route path="/Viewrooms" element={<Viewrooms />} />
          <Route path="/Booking_sucess" element={<Booking_sucess />} />
          <Route path="/Booking_failed" element={<Booking_failed />} />
        </Routes>

      </Router>

      <div className="tap-top" onClick={handleScrollTop} style={{ cursor: 'pointer' }}>
        <div>
          <i className="fas fa-angle-up"></i>
        </div>
      </div>
    </div>
  );
}

export default App;

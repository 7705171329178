import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function Booking() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const checkInDate = query.get('checkInDate');
    const checkOutDate = query.get('checkOutDate');
    const totalrooms = parseInt(query.get('totalrooms'), 10);
    const totalguest = parseInt(query.get('totalguest'), 10);
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [rooms, setRooms] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 3;

    useEffect(() => {
        const formatDateForApi = (date) => {
            const [day, month, year] = date.split('-');
            return `${year}-${month}-${day}`;
        };

        const fetchRooms = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/RoomSearch', {
                    params: {
                        checkInDate: formatDateForApi(checkInDate),
                        checkOutDate: formatDateForApi(checkOutDate),
                        totalrooms: totalrooms,
                        totalguest: totalguest,
                        page: currentPage,
                    }
                });

                setRooms(response.data.rooms.rooms);
                setTotalPages(Math.ceil(response.data.rooms.total / itemsPerPage));
            } catch (error) {
                console.error('Error fetching room data:', error);
            }
        };

        fetchRooms();
    }, [currentPage, checkInDate, checkOutDate, totalrooms, totalguest]);

    const paginatedRooms = rooms.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    return (
        <div>
            <Header />

            <div className="home_section hotel_layout slide-1 p-0">
                <img src="https://img.vistarooms.com/gallery/idyllic-farmhouse-a-glasshouse-29e2c9.jpg" style={{ width: "100%", height: "40rem" }} alt="Hotel" />
            </div>

            <section style={{ background: "#f9f9f9" }}>
                <div className="container">
                    <div className="list-view row content grid">
                        {paginatedRooms.length > 0 ? paginatedRooms.map((room) => (
                            <div key={room.id} className="list-box col-12 popular grid-item wow fadeInUp">
                                <div className="list-img">
                                    <Link to={`/Viewrooms/${room.id}`} className="img-link">
                                        <img
                                            src={`https://admin.abhyaranyan.com/RoomsUploadimage/${room.roomImage}`}
                                            className="img-fluid blur-up lazyload"
                                            alt="Room"
                                        />
                                    </Link>
                                </div>
                                <div className="list-content">
                                    <div className="offer-box">
                                        <i className="fas fa-fire"></i> {room.airCondition}
                                    </div>
                                    <div>
                                        <h5>{room.roomType}</h5>
                                        <h5>{room.location}</h5>
                                        <p>{room.description}</p>
                                        <div className="rating">
                                            {[...Array(5)].map((_, starIndex) => (
                                                <i key={starIndex} className={`fas fa-star ${starIndex < room.rating ? 'filled' : ''}`}></i>
                                            ))}
                                            <span>{room.reviews} reviews</span>
                                        </div>
                                        <div className="price">
                                            <p className="mb-0">
                                                <del>&#8377;{room.oldRent}</del>
                                                &#8377;{room.rent} <span>/ per night</span>
                                            </p>
                                            <p className="mb-0">Login & unlock a secret deal</p>
                                        </div>
                                        <Link to={`/Viewrooms?${query}&id=${room.id}`} className="btn btn-solid color1 book-now">
                                            Book Now
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <p>No rooms available.</p>
                        )}
                    </div>
                    <br />
                    <div className="pagination-controls">
                        <button className="btn btn-outline-primary" onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span style={{ margin: '0 1rem' }}>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-outline-primary" onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </div>
                </div>
            </section>

            <br />

            <Footer />
        </div>
    );
}

export default Booking;

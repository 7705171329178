import React, { useState, useEffect, memo } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css"; // Import lightbox styles
import './Gallerysection.css';
import Lightbox from 'react-image-lightbox';

const MemoizedLightbox = memo(Lightbox); // Memoized Lightbox component

const Gallerysection = () => {
    const [galleryItems, setGalleryItems] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const fetchGallery = async () => {
            try {
                const response = await fetch('https://api.abhyaranyan.com/api/Abhyaranyan/Homepage');
                const data = await response.json();
                if (data.succeeded && data.data && data.data.gallery) {
                    setGalleryItems(data.data.gallery);
                }
            } catch (error) {
                console.error('Error fetching gallery data:', error);
            }
        };

        fetchGallery();
    }, []);

    const preloadImage = (src) => {
        const img = new Image();
        img.src = src;
    };

    const openLightbox = (index) => {
        const currentSrc = `https://admin.abhyaranyan.com/RoomsUploadimage/${galleryItems[index].image}`;
        const nextSrc = `https://admin.abhyaranyan.com/RoomsUploadimage/${galleryItems[(index + 1) % galleryItems.length].image}`;
        const prevSrc = `https://admin.abhyaranyan.com/RoomsUploadimage/${galleryItems[(index + galleryItems.length - 1) % galleryItems.length].image}`;

        preloadImage(currentSrc);
        preloadImage(nextSrc);
        preloadImage(prevSrc);

        setCurrentImage(index);
        setIsOpen(true);
    };

    const closeLightbox = () => {
        setIsOpen(false);
    };

    const goToPrevious = () => {
        setCurrentImage((currentImage + galleryItems.length - 1) % galleryItems.length);
    };

    const goToNext = () => {
        setCurrentImage((currentImage + 1) % galleryItems.length);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div>
            <section className="ratio3_2 pt-0">
                <div className="container-fluid">
                    <div className="title-3">
                        <span className="title-label">our</span>
                        <h2>gallery<span>gallery</span></h2>
                    </div>
                    <div className="row">
                        <div className="col p-0">
                            <Slider {...settings} className="slide-4 no-arrow">
                                {galleryItems.map((item, index) => (
                                    <div key={item.id} onClick={() => openLightbox(index)}>
                                        <div className="gallery-box">
                                            <div className="gallery-img">
                                                <div className="overlay"></div>
                                                <img
                                                    src={`https://admin.abhyaranyan.com/RoomsUploadimage/${item.image}`}
                                                    alt={item.title}
                                                    className="img-fluid lazyload bg-img"
                                                    style={{ width: "95%" , cursor :"pointer" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            {isOpen && (
                <MemoizedLightbox
                    mainSrc={`https://admin.abhyaranyan.com/RoomsUploadimage/${galleryItems[currentImage].image}`}
                    nextSrc={`https://admin.abhyaranyan.com/RoomsUploadimage/${galleryItems[(currentImage + 1) % galleryItems.length].image}`}
                    prevSrc={`https://admin.abhyaranyan.com/RoomsUploadimage/${galleryItems[(currentImage + galleryItems.length - 1) % galleryItems.length].image}`}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={goToPrevious}
                    onMoveNextRequest={goToNext}
                />
            )}
        </div>
    );
}

export default Gallerysection;

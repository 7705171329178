import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Gallery.css';
import Header from './Header';
import Footer from './Footer';
import Gallery1 from '../src/images/Gallery1.png'
const Gallery = () => {

  const [galleryData, setGalleryData] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
        console.log('API Response:', response.data); // Check the response structure
        if (Array.isArray(response.data.data.galleries)) {
          setBanners(response.data.data.galleries);
          console.log("-----", response.data.data.galleries);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    const fetchGalleryData = async () => {
      try {
        const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/GallaryList');
        if (response.data.succeeded) {
          setGalleryData(response.data.data.gallery);
        }
      } catch (error) {
        console.error('Error fetching gallery data:', error);
      }
    };

    fetchGalleryData();

    fetchBanners();
  }, []);


  return (
    <div>
      <Header />

      {Array.isArray(banners) && banners.map((banner, index) => (
        <div className="home_section hotel_layout slide-1 p-0">
          <div>
            <div className="home">
              <img src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`} class="bg-img img-fluid blur-up lazyload" alt="" />

              <div className="home-content">
                <div>

                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <section>
        <div className="title-3">
          <h2>gallery<span>gallery</span></h2>
        </div>

        <div className="desc-box">
          <div className="menu-part" id="gallery">
            <div className="container-fluid p-0 ratio3_2">
              <div className="row zoom-gallery">
                {galleryData.map((item) => (
                  <div className="col-lg-4 col-sm-6" key={item.id}>
                    <div className="overlay">
                      <a href={`https://admin.abhyaranyan.com/RoomsUploadimage/${item.image}`}>
                        <div className="overlay-background">
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </div>
                        <img
                          src={`https://admin.abhyaranyan.com/RoomsUploadimage/${item.image}`}
                          alt={item.title}
                          className="img-fluid blur-up lazyload bg-img"
                        />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <br />
      </section>

      <Footer />
    </div>
  );
};

export default Gallery;

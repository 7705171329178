import React from 'react'

const Aboutsection = () => {
    return (
        <div>
            <section class="about-section three-image">
                <div class="container">
                    <div class="title-3">
                        <span class="title-label">about</span>
                        <h2>our hotel<span>hotel</span></h2>
                    </div>
                    <div class="row">
                        <div class="col-xl-8">
                            <div class="image-section">
                                <div class="img-box">
                                    <img src="../assets/images/mix/emergency/1-3.png" data-tilt data-tilt-perspective="110"
                                        data-tilt-speed="400" data-tilt-max="1.2" class="img-fluid fluid1  lazyload" alt="" />
                                    <div class="no-class">
                                        <h3>01</h3>
                                    </div>
                                </div>
                                <div class="img-box">
                                    <img src="../assets/images/mix/emergency/2-4.png" data-tilt data-tilt-perspective="110"
                                        data-tilt-speed="400" data-tilt-max="1.2" class="img-fluid fluid1 lazyload" alt="" />
                                    <div class="no-class">
                                        <h3>02</h3>
                                    </div>
                                </div>
                                <div class="img-box">
                                    <img src="../assets/images/mix/emergency/3-5.png" data-tilt data-tilt-perspective="110"
                                        data-tilt-speed="400" data-tilt-max="1.2" class="img-fluid fluid1 lazyload" alt="" />
                                    <div class="no-class">
                                        <h3>03</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="about-text">
                                <div>
                                    <h5>Exclusive <span>Farm-to-Table Dinner</span> at Our Farm Stay</h5>
                                    <h2>Redefining Luxury<span>A New Era in Farm Stay Hospitality luxury </span></h2>
                                    <p>
                                        The phrase "redefines the luxury" signifies a transformative farm stay experience where rustic charm meets sophisticated comfort. Imagine an exceptional retreat blending modern indulgences with the tranquil beauty of the countryside, creating a uniquely unforgettable escape.
                                    </p>
                                    {/* <img src="../assets/images/mix/signature.png" class="img-fluid  lazyload" alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Aboutsection

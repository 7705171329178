import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Orchid.css'; // Assuming you have a CSS file for additional styles

const Orchid = () => {
  const [banners, setBanners] = useState([]);
  const [orchardInfo, setOrchardInfo] = useState({ description: '', images: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [bannerResponse, orchardResponse] = await Promise.all([
          axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList'),
          axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/OrchardPage'),
        ]);

        // Handling banner data
        if (Array.isArray(bannerResponse.data.data.orchidlist)) {
          setBanners(bannerResponse.data.data.orchidlist);
        } else {
          console.error('Unexpected banner response format:', bannerResponse.data);
        }

        // Handling orchard data
        const orchardData = orchardResponse.data.data;
        if (orchardData) {
          setOrchardInfo({
            description: orchardData.orchardDescription.description || '',
            images: orchardData.orchardImage || [],
          });
        } else {
          console.error('Unexpected orchard response format:', orchardResponse.data);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Header />

      {Array.isArray(banners) && banners.map((banner, index) => (
        <div className="home_section hotel_layout slide-1 p-0" key={index}>
          <div>
            <div className="home">
              <img
                src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`}
                className="img-fluid lazyload bg-img bg-img1"
                alt=""
              />
              <div className="home-content">
                <div></div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <section className="category-sec ratio3_2 section-bb-space">
        <div className="container">
          <div className="title-1 title-5">
            <span className="title-label">The</span>
            <h2>Orchard</h2>
            <p>{orchardInfo.description}</p>
          </div>
          <div className="row">
            <div className="col">
              <Slider {...sliderSettings}>
                {orchardInfo.images.map((image, index) => (
                  <div key={index} className="category-box wow fadeInUp">
                    <div className="img-category">
                      <img
                        src={`https://admin.abhyaranyan.com/RoomsUploadimage/${image.image}`}
                        alt={`Orchard Image ${index + 1}`}
                        className="img-fluid blur-up lazyload bg-img"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%' }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-right"></i> {/* Use Font Awesome or any other icon library */}
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%' }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-left"></i> {/* Use Font Awesome or any other icon library */}
    </div>
  );
};

export default Orchid;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Packages = ({ dynamicId }) => {
    const [banners, setBanners] = useState([]);
    const [highlights, setHighlights] = useState(null);
    const [itineraries, setItineraries] = useState([]);
    const [galleryData, setGalleryData] = useState([]);


    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
                console.log('API Response:', response.data);
                if (Array.isArray(response.data.data.packageslist)) {
                    setBanners(response.data.data.packageslist);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };

        fetchBanners();
    }, []);


    useEffect(() => {
        const fetchHighlights = async () => {
            try {
                const response = await axios.get(`https://api.abhyaranyan.com/api/Abhyaranyan/PackagesPage`);
                const data = response.data.data;

                if (data) {

                    setHighlights(response.data.data.highlights);
                    setItineraries(data.itinerarys || []);
                    setGalleryData(data.gallery || []);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };


        fetchHighlights();
    }, []);


    if (!highlights) {
        return <div>Loading...</div>;
    }

    const inclusionItems = highlights.description && typeof highlights.description === 'string' ?
        highlights.description.match(/<h4[^>]*>Inclusion<\/h4>(.*?)<\/ul>/s)?.[1] || '<p>No inclusions listed</p>' :
        '<p>No inclusions listed</p>';

    const exclusionItems = highlights.description && typeof highlights.description === 'string' ?
        highlights.description.match(/<h4[^>]*>Exclusion<\/h4>(.*?)<\/ul>/s)?.[1] || '<p>No exclusions listed</p>' :
        '<p>No exclusions listed</p>';



    return (
        <div>
            <Header />

            {Array.isArray(banners) && banners.length > 0 && banners.map((banner, index) => (
                <div className="home_section hotel_layout slide-1 p-0" key={index}>
                    <div>
                        <div className="home">
                            <img
                                src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`}
                                className="bg-img img-fluid"
                                alt=""
                            />
                            {console.log("&&&&&&&", `https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`)}
                            <div className="home-content">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <section className="single-section bg-inner small-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 small-section pb-0">
                            <div className="description-section tab-section">
                                <div className="menu-top menu-up">
                                    <ul className="nav nav-tabs" id="top-tab" role="tablist">
                                        <li className="nav-item">
                                            <a
                                                data-bs-toggle="tab"
                                                className="nav-link active"
                                                href="#highlight"
                                            >
                                                highlight
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a data-bs-toggle="tab" className="nav-link" href="#itinerary">
                                                itinerary
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a data-bs-toggle="tab" className="nav-link" href="#gallery">
                                                gallery
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="description-details tab-content" id="top-tabContent">
                                    <div className="menu-part about tab-pane fade show active" id="highlight">
                                        <div className="about-sec">
                                            {highlights.map((highlight, index) => (
                                                <div key={index} className="about-sec">
                                                    {highlight.tittle && <h4>{highlight.tittle}</h4>}
                                                    <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: highlight.description }} />
                                                    {highlight.image && (
                                                        <div className="detail-img">
                                                            <img
                                                                src={`https://admin.abhyaranyan.com/RoomsUploadimage/${highlight.image}`}
                                                                className="img-fluid blur-up lazyload"
                                                                alt={highlight.tittle || 'Highlight Image'}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>


                                    <div className="menu-part accordion tab-pane fade" id="itinerary">
                                        <div id="accordion" className="accordion-plan">
                                            {itineraries.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id={`heading${index}`}>
                                                        <h5 className="mb-0">
                                                            <button
                                                                className={`btn btn-link ${index === 0 ? '' : 'collapsed'}`}
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#collapse${index}`}
                                                                aria-expanded={index === 0 ? 'true' : 'false'}
                                                                aria-controls={`collapse${index}`}
                                                            >
                                                                {item.tittle}
                                                            </button>
                                                        </h5>
                                                    </div>
                                                    <div
                                                        id={`collapse${index}`}
                                                        className={`collapse ${index === 0 ? 'show' : ''}`}
                                                        aria-labelledby={`heading${index}`}
                                                        data-bs-parent="#accordion">
                                                        <div className="card-body">
                                                            {item.description}
                                                            <div className="highlight">
                                                                <ul>
                                                                    {item.mealtype.map((meal, idx) => {
                                                                        let imageUrls = [];

                                                                        if (Array.isArray(item.image)) {
                                                                            imageUrls = item.image.map(img => `https://admin.abhyaranyan.com/roomsuploadimage/${img.trim()}`);
                                                                        } else if (typeof item.image === 'string') {
                                                                            imageUrls = item.image.split(',').map(img => `https://admin.abhyaranyan.com/roomsuploadimage/${img.trim()}`);
                                                                        }

                                                                        return (
                                                                            <li key={idx}>
                                                                                <img
                                                                                    src={imageUrls[idx]}
                                                                                    className="img-fluid blur-up lazyload"
                                                                                    alt={meal}
                                                                                />{" "}
                                                                                {meal}
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="menu-part tab-pane fade" id="gallery">
                                        <div className="container-fluid p-0 ratio3_2">
                                            <div className="row zoom-gallery">
                                                {Array.isArray(galleryData) && galleryData.length > 0 ? (
                                                    galleryData.map((image, index) => {
                                                        let imageUrls = [];

                                                        if (Array.isArray(image.image)) {
                                                            imageUrls = image.image.map(img => `https://admin.abhyaranyan.com/RoomsUploadimage/${img.trim()}`);
                                                        } else if (typeof image.image === 'string') {
                                                            imageUrls = image.image.split(',').map(img => `https://admin.abhyaranyan.com/RoomsUploadimage/${img.trim()}`);
                                                        }

                                                        return (
                                                            <div className="col-lg-4 col-sm-6" key={index}>
                                                                <div className="overlay">
                                                                    <a href={image.url}>
                                                                        <div className="overlay-background">
                                                                            <i className="fa fa-plus" aria-hidden="true" />
                                                                        </div>
                                                                        {imageUrls.map((url, idx) => (
                                                                            <img
                                                                                key={idx}
                                                                                src={url}
                                                                                alt={image.alt || 'Gallery Image'}
                                                                                className="img-fluid blur-up lazyload bg-img"
                                                                            />
                                                                        ))}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div>Loading Gallery...</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Packages;

import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Booking_sucess = () => {
    return (
        <div>

            <Header />
            <section class="bg-inner section-b-space success-section" >
                <div class="container" style={{ marginTop: "4rem" }}>
                    <div class="row success-detail mt-0">
                        <div class="col">
                            <img src="../assets/images/hotel/booking-success.png" class="img-fluid blur-up lazyload" alt="" />
                            <h2>Payment Successful ! get ready for comfortable stay.</h2>
                            <p>thank you for you payment. we have received your payment successfully. your transaction ID is
                                "SHJG12155215", you will get an email invoice soon!</p>
                            <button type="submit" class="btn btn-solid color1">download invoice</button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Booking_sucess
